.project-detail__milestone-cont {
  padding: 10px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  background: white;
}

.acitvity-report_link-item {
  display: grid;
  padding: 5px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: grey;
  }

  a {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    color: #0d6efd !important;
    text-decoration: underline !important;
    text-overflow: ellipsis;
  }
}