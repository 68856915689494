.project-performance__timeline-cont {
  padding: 10px;

  label {
    font-size: 18px;
    font-weight: 500;
  }
  .project-performance__timeline {
    width: 100%;
    text-align: center;
    font-weight: 500;
    color: white;
    padding: 5px;
    height: 60px;
    border: 2px solid #dddddd;

    &:not(:first-child):not(:last-child) {
      border-right: none;
    }

    &:nth-child(1) {
      border-right: none;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }

    &:nth-last-child(1) {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
}

.project-performance__stage-cont {
  border: 2px solid #c8c9ca;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: grey;
  }

  p {
    font-size: 18px;
    font-weight: 500;
  }
}

.project-performance-detail-cont {
  margin: 10px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: grey;
  }

  p {
    font-size: 18px;
    font-weight: 500;
  }

  button {
    border: none;
    height: 30px;
    width: 30px;
    display: flex;
    border-radius: 50%;
    padding: 10px;
    margin: auto 0px 10px 0px;
    background: #00a1ff;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
      color: white;
    }
  }
}

.project-performance-overall {
  text-align: center;
  padding: 10px;
  white-space: pre;
  width: 15% !important;
  min-width: 200px !important;
  background-color: aquamarine;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  margin-left: auto;

  @media screen and (max-width: 567px) {
    width: 100% !important;
    transform: translateX(-300px);
  }
}