.landing-page {
  background: url( '../../assets/landing-bg.png' );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow-y: auto;
  font-family: cursive;
}

.landing-page-header {
  background: #068d8cd1;
  color: white;
  padding: 50px;
  min-height: 20%;
}

.landing-page-pengenalan {
  background: #fffffff2;
  padding: 100px 50px 100px 50%;
  min-height: 40%;
}

.landing-page-tujuan {
  background: #068d8cd1;
  color: white;
  padding: 100px 50% 100px 50px;
  min-height: 40%;
}

.landing-page-to-dashbaord {
  position: absolute;
  bottom: 20px;
  right: 40px;
  border: none;
  border-radius: 8px;
  background: #26cbc994;
  font-size: 18px;
  padding: 10px;
  box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
	transform: translatey(0px);
	animation: float 2s ease-in-out infinite;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.4);
		transform: translatey(0px);
	}
}
