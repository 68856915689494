p, span, label {
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: justify;
  word-break: break-word;
}

.card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.module-container {
  /* background: #f9f9f9; */
  background: url('./assets/dashboard-bg.png');
  background-position: right;
  background-size: cover;
  padding: 10px;
  min-height: calc( 100vh - 70px );
}

.app-close-button {
  border: none;
  background: #055160;
  color: white;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.add-button {
  border-radius: 50% !important;
  padding: 5px !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.form-date-picker {
  width: 100%;
  height: 50px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding-left: 10px;
} 

.status-bullet {
  padding: 5px 10px;
  border-radius: 25px;
  margin: 2px;
}

.form-select,
.form-control {
  height: 50px;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
}

button:disabled {
  opacity: 0.5;
}

hr {
  margin: 0px 10px 10px 10px !important;
}

.ml-auto {
  margin-left: auto;
}

.modal-title {
  font-size: 24px;
}

.content-title {
  font-size: 20px;
  font-weight: 500;
}

.pre_color_purple {
  color: #F495B7;
}

.pre_color_white {
  color: #D1D5DB;
}

.pre_color_blue {
  color: #4FA7DF;
}

.pre_color_dark-green {
  color: #226E84;
}

.pre_color_blue {
  color: #FFD234;
}

.border-gradient {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 5px;
}

.border-gradient-purple {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

.border-gradient-green {
  border-image-source: linear-gradient(to left, #00C853, #B2FF59);
}

.border-gradient-blue {
  border-image-source: linear-gradient(45deg, #72ffec, #00c6ff);
}

@media screen and ( max-width: 1000px ) {
  .module-container {
    margin: 0px !important;
  }
}

@media screen and ( max-width: 576px ) {
  p, span, label {
    font-size: 12px;
  }
}