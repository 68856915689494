.at-project-mngmt__form-card {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 10px;
  box-shadow: 0px 0px 5px 5px #ced4da3b;
}

.at-project-mngmt__milestone-cont {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.at-project-mngmt__form-label {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  padding-left: 10px;
}