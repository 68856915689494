.paginated-header {
  display: flex;
  transform: translateY(-1px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.paginated-table__header-cont{
  border: 1px solid #63c0fb;
  width: 100%;
  padding: 10px;
  height: 50px;
  display: flex;
  color: white;
  background: #4FA7DF;
  border-right: none;
  align-items: center;

  &:nth-child(1){
    border-top-left-radius: 10px;
  }

  &:nth-last-child(1){
    border-right: 1px solid #63c0fb;
    border-top-right-radius: 10px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  @media screen and ( max-width: 1000px ) {
    min-width: 150px;

    p{
      font-size: 12px;
    }
  }
}

.paginated-table__content-cont {
  display: flex;
  width: 100%;
  transition: all 500ms;

  &:hover {
    transform: translateY( -2px );
  }
}

.paginated-table__content-column {
  border: 1px solid #d7d7d7;
  width: 100%;
  padding: 10px;
  min-height: 50px;
  background-color: #fffffff0;
  display: flex;
  align-items: center;
  border-right: none;
  transform: translateY( -2px );

  &:nth-last-child(1){
    border-right: 1px solid #d7d7d7;
  }

  p {
    word-break: break-word;
    font-size: 12px;
    margin-bottom: 0px;
  }
  
  @media screen and ( max-width: 1000px ) {
    min-width: 150px;

    p{
      font-size: 10px;
    }
  }
}

.paginated-pagination_data-count {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.paginated-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.paginated-pagination__button {
  padding: 10px;
  width: 35px;
  height: 35px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  background: rgb(231, 231, 231);
  border-radius: 50%;

  &:hover:not([disabled]) {
    color: white;
    border: 2px solid #4FA7DF;
  }

  &:disabled {
    border: none;
  }
}

.paginated-pagination__button-selected {
  border: 2px solid #4FA7DF;
  background: #57acc4;
  color: white;
  font-weight: 700;
}

.paginated-table__content-action-btn {
  border: none;
  background: #00a1ff;
  border-radius: 5px;
  display: flex;
  width: 40px;
  height: 38px;
  margin-right: 0.5rem;
  justify-content: center;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    color: white;
  }

  @media screen and ( max-width: 1000px ) {
    font-size: 12px;
  }
}

.paginated-table__header-sort-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
  border: none;
  background-color: transparent;

  svg {
    width: 15px;
    height: 15px;
    color: white;
  }
}