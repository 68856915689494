.back-button {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid grey;
  border-radius: 8px;
  width: 40px;
  height: 40px;
}

.pl-project__summary-cont {
  padding: 20px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  background: white;

  label {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    color: grey;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
  }
}

.pl-project__summary-item {
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
  padding-right: 20px;

  &:hover {
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
  }
}

.pl-project__summary-item-content {
  width: 100%;
  min-width: 100px;
  display: flex;
  align-items: center;
  word-break: break-word;
  padding-left: 10px;
  border-left: 1px solid #ededed;

  span {
    color: white;
    font-size: 12px;
    font-weight: 600;
    min-width: 25px;
  }

  &:nth-child( 1 ) {
    border-left: none;
  }
}