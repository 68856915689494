.project-detail__milestone-cont {
  padding: 10px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  background: white;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin-bottom: 0px;
  }
}

.project-detail__milestone-item {
  display: flex;
  justify-content: space-between;
  gap: 4px;

  &:not(:last-child) {
    border-bottom: 1px solid #D1D5DB;
  }

  .project-detail__milestone-item-content:not(:last-child) {
    padding-right: 4px;
    border-right: 1px solid #D1D5DB;
  }
}

.project-detail__milestone-item-content {
  width: 100%;
  min-width: 100px;
  word-break: break-word;

  label {
    width: 100%;
    text-align: center;
  }
  
  p {
    margin-bottom: 5px;
    text-align: center;
  }
}

.project-detail__milestone-status {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin: 2px auto;
}
  
.acitvity-report_link-item {
  display: grid;
  padding: 5px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: grey;
  }

  a {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    color: #0d6efd !important;
    text-decoration: underline !important;
    text-overflow: ellipsis;
  }
}

.button-expand {
  border: none;
  background: white;
  width: 70px;
  height: 30px;

  svg {
    background: #dcf2ff;
    height: 30px;
    width: 30px;
    color: #009dff;
    padding: 5px;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
  }
}

.badge__status {
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 10px;
  width: 100px;
  word-break: break-word;
  text-align: center;
}

.stage-milestone-overall {
  text-align: center;
  padding: 10px;
  white-space: pre;
  width: 15% !important;
  min-width: 200px !important;
  background-color: aquamarine;
  border: 1px solid #D1D5DB;
  border-radius: 8px;

  @media screen and (max-width: 567px) {
    width: 100% !important;
    transform: translateX(-300px);
  }
}