/* Top Section (Logo) */

.navbar {
  background-color: #226e84;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Navigation Bar Section */

.nav-menu {
  background-color: #226e84;
  width: 80px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 20px;
  transition: all 500ms;
  z-index: 997;
}

.nav-menu-expand {
  width: 250px;
  background-color: #226e84;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 20px;
  transition: all 500ms;
  z-index: 997;
}

.nav-menu-item {
  padding: 0%;
}

.nav-item-text {
  display: none;
}

.nav-item-text-expand {
  display: flex;
  margin: 0 30px;
}

.item-bars {
  margin: 5px 10px;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
}

.item-bars-active,
.item-bars:hover {
  background-color: #1c5363;
  border-radius: 5px;
}

.top-bar__user-info {
  padding: 10px 20px;

  p {
    margin-bottom: 0px;
  }

  #display-name {
    font-size: 20px;
    font-weight: 600;
  }

  #role {
    font-size: 14px;
  }
}


.nav-text-expand {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
}

.nav-text-collapse {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-text {
  color: black;
  text-decoration: none;
  margin: 5px 0;
  width: 100%;
  height: 100%;
}

.nav-text svg {
  width: 18px;
  height: 18px;
  margin: 5px 0;
}

ul {
  padding: 0%;
  width: 100%;
}

/* Expand Button Section */

.nav-expand-btn {
  position: fixed;
  bottom: 15px;
  font-size: 25px;
}

.expand-btn {
  color: white;
  transform: rotateZ(0deg);
  transition: 850ms;
}

.collapse-btn {
  color: white;
  transform: rotateZ(-180deg);
  transition: 850ms;
}

a:hover {
  color: white !important;
}

@media screen and (max-width: 1000px) {
  .nav-menu {
    transform: translateX(-100px);
  }

  .nav-menu-expand {
    transform: translateX(0px);
  }
}
