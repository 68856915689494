.at-search__expand-btn {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  padding: 0px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 15px;

  svg {
    margin-left: 10px;
    width: 18px;
    color: #0d6efd;
    height: 18px;
  }
}