.slider-card-cont {
  display: flex;
  width: 100%;
  position: relative;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.slider-card {
  box-shadow: 0 0px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 20px;
  background: white;
  font-weight: 600;
  min-width: 200px;
  margin: 10px;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  button {
    color: #3B82F6;
    border-radius: 50%;
    padding: 5px;
    background: #DBEAFE;
    width: 30px;
    height: 30px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in;
  }

  button:hover {
    width: 40px;
    height: 40px;
  }

  button > svg {
    width: 18px;
    height: 18px;
  }

  &:nth-child( 1 ){
    margin-left: 20px;
  }

  &:nth-last-child( 1 ){
    margin-right: 20px;
  }
}

.slider-card-navigate-button {
  position: absolute;
  height: 100%;
  border: none;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  svg {
    color: #afafaf;
  }

  &:hover {
    border-radius: 10px;
    background-color: #e4e4e457;

    svg {
      color: black;
    }
  }
}