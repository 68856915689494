.at-home__card {
  box-shadow: 0 0px 4px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  padding: 20px;
  font-weight: 600;
  min-width: 200px;
  margin: 10px;
  background: white;

  label {
    font-size: 12px;
    color: grey;
  }

  p {
    font-size: 24px;
    font-weight: 500;
  }
}