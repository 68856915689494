.at-tab__item {
  height: 50px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: none;
  margin-right: 5px;
  background-color: #D1D5DB;
  color: #6B7280;
  padding: 0px 20px;
}

.at-tab__selected {
  height: 50px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #13142b;
  margin-right: 5px;
  color: white;
  font-weight: 600;
  padding: 0px 20px;
}