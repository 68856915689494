.at-project-mngmt__form-card {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 10px;
  box-shadow: 0px 0px 5px 5px #ced4da3b;
}

.at-project-mngmt__assessment-status {
  padding: 4px 10px;
  border-radius: 25px;
  margin: 2px;
  cursor: default;
}

.at-project-mngmt__pending-task_timestamp {
  margin-top: 10px;
  margin-left: auto;
  font-size: 12px;
  color: grey;
}

.at-project-mngmt__white-card {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 100%;

  .title {
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
  }
}

.at-project-mngmt__redirect {
  cursor: pointer;
  color: blue;
}