.module-header__card {
  display: flex;
  padding: 10px;
  margin: -10px -10px 20px -10px;
  background: white;
  box-shadow: 0.0px 1.0px 3.0px 0px rgba(16, 24, 40, 0.1),0.0px 2.0px 1.0px 0px rgba(16, 24, 40, 0.06),0.0px 1.0px 1.0px 0px rgba(0, 0, 0, 0.08);
}

.module-header__card svg {
  width: 60px;
  height: 60px;
  margin: auto 20px;
  padding: 10px;
  border-radius: 12px;
  background: #c9ebf5;
  color: #226e84;
}

.module-header__card-title {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 25px;
  font-weight: 500;
  color: #226e84;
}

.module-header__card-subtitle {
  color: grey;
}

.module-header__card-action-cont {
  margin-left: auto;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: flex-end;
}

@media screen and ( max-width: 100px ) {
  
}