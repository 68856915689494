.project-detail__detail-cont-bg,
.project-detail__detail-cont {
  padding: 20px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 2px 2px #818181;

  label {
    font-size: 12px;
    font-weight: 500;
    color: grey;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    word-break: break-word;
  }
}

.project-detail__detail-cont-bg {
  background: url( '../../../../assets/project-bg.png' );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.project-detail__milestone-cont {
  padding: 10px;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  background: white;
}
  
.acitvity-report_link-item {
  display: grid;
  padding: 5px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: grey;
  }

  a {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    color: #0d6efd !important;
    text-decoration: underline !important;
    text-overflow: ellipsis;
  }
}

.activity-report_summary-card {
  margin-bottom: 10px;
  border: 1px solid #ebebeb;
  padding-top: 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 1px #e7e7e7;
}

.year-selection {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 3px solid #226e84;
  width: fit-content;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  label {
    margin-bottom: 5px;
    margin-right: 50px;
    font-size: 18px;
    font-weight: 500;
  }
}

.activity-report_summary {
  display: flex;
  padding: 10px;

  ul {
    list-style: none;
    padding: 0px;
    width: 100%;

    &:nth-child( 1 ) {
      border-left: 1px solid black;
    }
  }

  li {
    border: 1px solid black;
    padding: 5px;
    font-size: 14px;
    text-align: center;
    border-left: none;
    border-bottom: none;
    min-height: 35px;
  }

  .header {
    font-weight: 600;
  }

  .status {
    min-height: 35px;
  }

  .last-child {
    border-bottom: 1px solid black;
    min-height: 40px;
  }
}