.react-pdf-viewer__filename {
  border-bottom: 2px solid #b3b3b3;
  background: #e1e1e1;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    color: #b70000;
    margin-right: 10px;
  }
}

.react-pdf-viewer__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-top: 2px solid #b3b3b3;
  padding: 15px;
  background: #e1e1e1;
}

.react-pdf__Page {
  background-color: grey;
}

.react-pdf__Page__canvas {
  margin: auto;
}

.project-assessment__progres-btn {
  border: none;
  background: none;
  width: 200px;
  color: #0089ff;
  line-height: 100%;
  font-size: 14px;
  font-weight: 500;

  p {
    font-size: 20px;
    padding: 10px;
    background: #def1ff;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #0089ff;
    width: 50px;
    line-height: initial;
    min-width: 25px;
    margin: auto;
    height: 50px;
    margin-bottom: 5px;
  }

  .selected {
    background: #0089ff;
    color: white 
  }
}

.project-assessment__assessment-column {
  background: #f3f3f3;
  padding-bottom: 10px;
  overflow: auto;

  .title {
    font-size: 18px;
    padding: 10px 0px;
    font-weight: 500;
  }

  .assessment-card {
    background: white;
    margin: 0px -5px;
    padding: 10px;
    font-weight: 500;
    border-radius: 8px;
    height: 100%;
    box-shadow: 0px 4px 5px 0px #00000010;
    margin-bottom: 10px;
  }

  .radio-label {
    font-size: 14px;
  }
}

.project-assessment__preview-card {
  border-left: 5px solid;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px #00000010;
  height: 100%;
  min-height: 150px;

  label {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    padding-right: 10px;
  }

  button {
    border: none;
    background: none;
    font-size: 14px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  p {
    color: grey;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
}

.project-assessment__overall-card {
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  padding: 20px;
  margin: 10px 0px;
}