.at-card-list__cont {
  margin: 0px !important;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  background: #fdfdfd;
}

.at-card-list__card-cont {
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  background: #fdfdfd;
  padding: 15px 15px 0px 15px;
  position: relative;

  label {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: grey;
  }

  p {
    margin-bottom: 5px;
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
  }

  @media screen and ( max-width: 570px ) {
    label {
      font-size: 12px;
    }
  
    p {
      line-height: 14px;
      font-size: 14px;
    }
  }
}

.at-card-list__status {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 10px;
  width: fit-content;
}

.at-card-list__action {
  border: none;
  background: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.at-card-list__action-cont {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #D1D5DB;
}

.at-card-list__no-result {
  margin: 50px 0px !important;
  text-align: center;
}