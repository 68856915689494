.info-card__cont {
  display: flex;
  margin: 10px 0px;
  padding: 10px 10px;
  overflow-x: scroll;
}

.info-card__detail-card {
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  background: white;
  width: 300px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
  margin-right: 20px;
  padding: 10px;
  border-bottom: 5px solid;
  box-shadow: #0000001a 0px 0px 10px 0px;

  &:nth-child(n) {
    border-bottom-color: #FF0000;
  }
  &:nth-child(2n) {
    border-bottom-color: #00C2FF;
  }
  &:nth-child(3n) {
    border-bottom-color: #8000FF;
  }
  &:nth-child(4n) {
    border-bottom-color: #FF0099;
  }
}

.info-card__detail-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

.info-card__detail-value {
  font-weight: 700;
  font-size: 20px;
  margin: 10px 5px
}

/* Hide scrollbar for Chrome, Safari and Opera */
.info-card__cont::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.info-card__cont {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media screen and ( max-width: 1000px ) {
  .info-card__detail-card {
    width: 200px;
    min-width: 200px;
  }

  .info-card__detail-title {
    font-size: 14px;
  }

  .info-card__detail-value {
    font-size: 18px;
  }
}