.site-audit__audit-cont {
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background: #f7f7f7;

  .site-audit__section-cont {
    margin-bottom: 20px;

    .title{
      font-size: 18px;
      font-weight: 500;
      padding: 5px 10px 10px 5px;
      width: 100%;
      border-bottom: 1px solid lightgrey;
    }
  }
}

.project-assessment-preview-cont {
  padding: 10px;
  background: #cdcdcd;

  .project-assessment-criteria-cont {
    background: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;

    label {
      font-size: 18px;
      font-weight: 500;
    }

    p {
      display: flex;
      align-items: end;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}