.topbar-container {
  position: sticky;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #226e84;
  z-index: 999;

  .toggle-menu {
    margin-left: 30px;
    transition: all 500ms;
  }

  .toggle-menu-expand {
    margin-left: 200px;
    transition: all 500ms;
  }

  .dropdown-toggle {
    border: none;
    border-radius: 34px !important;
    background: #118db1 !important;
    margin-right: 10px;
    padding: 6px;
    z-index: 1;
  }

  .dropdown-toggle:hover {
    background: #222545 !important;
  }

  .dropdown-toggle img {
    width: 40px;
    height: 40px;
  }
  
  .logo-bars {
    margin-left: 20px;
    padding-top: 10px;
  }

  .dropdown-item:hover {
    background-color: #226E84;
  }
}